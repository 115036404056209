import React, { useState, useEffect } from 'react';
import api from '../configuracion/axiosconfig';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Alert, AlertTitle, Button, Modal, Snackbar, Table, TableContainer, TableHead, TableRow, TableBody, TableCell, Select, 
  MenuItem, TextField, Checkbox, Grid, Paper, Box, CssBaseline, Typography, InputLabel, Divider, 
  FormControlLabel, FormControl  } from '@mui/material';
import './ObjetivosMetas.css';

const tema = createTheme({
  palette: {
    primary: {
      main: '#424242',
    },
    secondary: {
      main: '#757575',
    },
    error: {
      main: '#ff5252',
    },
    background: {
      default: '#212529',
      paper: '#212529',
    },
    text: {
      primary: '#ffffff',
      secondary: '#bdbdbd',
    },
  },
  typography: {
    h4: {
      fontSize: '2rem',
      color: '#e0e0e0',
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
      },
    },
  },
});

const ObjetivosMetas = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [objetivos, setObjetivos] = useState([]);
  const [metas, setMetas] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState('');
  const [availableMetas, setAvailableMetas] = useState([]);
  const [metaSeleccionada, setMetaSeleccionada] = useState(null);
  const [objetivoSeleccionado, setObjetivoSeleccionado] = useState(null);
  const [modalData, setModalData] = useState({
    meta: '', fechaDesde: '', fechaHasta: '', valor: '', cumplido: false});
  const [modalOpen, setModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [metasCumplidas, setMetasCumplidas] = useState(true);
  const [objetivosCumplidos, setObjetivosCumplidos] = useState(true);
  const [alerta, setAlertOpen] = useState(false);
  const [mensajeAlerta, setAlertMessages] = useState([]);
  const [botonCrear, setBotonCrear] = useState('');
  const [botonEditar, setBotonEditar] = useState('');

  useEffect(() => {
    const usuario = JSON.parse(localStorage.getItem('usuario'));
    if(usuario.admin){
      setIsAdmin(true);

    }else{
      setUsuarioSeleccionado(usuario.id);
      fetchMetas(usuario.id);
    }

    cargarMetas();    
  }, []);

  const cargarMetas = async () =>{
    try {
      const token = localStorage.getItem('token'); 
      const usuario = JSON.parse(localStorage.getItem('usuario')); 
      const response =await api.get('/api/objetivo_meta', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'User-ID': usuario.id
        }
      });
      setAvailableMetas(response.data);
    } catch (error) {
      console.error('Error fetching available metas', error);
    }
  }


  const fetchMetas = async (usuarioId) => {
    try {
      const token = localStorage.getItem('token'); 
      const usuario = JSON.parse(localStorage.getItem('usuario')); 
      const response = await api.get('/api/objetivo_meta_usuario/usuario', {
        params: { usuario: usuarioId },
        headers: {
          'Authorization': `Bearer ${token}`,
          'User-ID': usuario.id
        }
      });
      const objetivos = [];
      const metas = [];

      response.data.forEach(item => {
        if (item.creadoAdmin) {
          objetivos.push(item);
        } else {
          metas.push(item);
        }
      });

      setObjetivos(objetivos);
      setMetas(metas);
    } catch (error) {
      console.error('Error fetching metas', error);
    }
  };

  const listarUsuarios = async () => {
    try {
      const token = localStorage.getItem('token'); 
      const usuario = JSON.parse(localStorage.getItem('usuario'));
      const response = await api.get('/api/usuarios/alumnos', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'User-ID': usuario.id
        }
      });
      setUsuarios(response.data);
    } catch (error) {
      console.error('Error al obtener usuarios:', error);
    }
  };


  const handleCreateUserMeta = async () => {
    try {
      const token = localStorage.getItem('token'); 
      const usuario = JSON.parse(localStorage.getItem('usuario')); 
  
      const fechaDesde = new Date(formatDate2(modalData.fechaDesde));
      const fechaHasta = new Date(formatDate2(modalData.fechaHasta));
  
      if (isEdit) {
        if (metaSeleccionada !== null) {
          const fechaMinima = new Date(fechaDesde);
          fechaMinima.setMonth(fechaDesde.getMonth() + 1);
  
          const fechaMaxima = new Date(fechaDesde);
          fechaMaxima.setMonth(fechaDesde.getMonth() + 3);
  
          if (fechaHasta >= fechaMinima && fechaHasta <= fechaMaxima) {
            await api.put(
              `api/objetivo_meta_usuario/${metaSeleccionada._id}`,
              {
                fechaDesde: fechaDesde.toISOString(),
                fechaHasta: fechaHasta.toISOString(),
                valor: modalData.valor,
                cumplido: modalData.cumplido,
              },
              {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'User-ID': usuario.id,
                },
              }
            );
          } else {
            throw new Error('Las metas deben tener un margen de fecha de 1 a 3 meses');
          }
          setMetaSeleccionada(null);
        } else if (objetivoSeleccionado !== null && isAdmin) {
          await api.put(
            `api/objetivo_meta_usuario/${objetivoSeleccionado._id}`,
            {
              fechaDesde: fechaDesde.toISOString(),
              fechaHasta: fechaHasta.toISOString(),
              valor: modalData.valor,
              cumplido: modalData.cumplido,
            },
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'User-ID': usuario.id,
              },
            }
          );
          setObjetivoSeleccionado(null);
        }
      } else {
        if (botonCrear === "crearMeta") {
          const fechaMinima = new Date(fechaDesde);
          fechaMinima.setMonth(fechaDesde.getMonth() + 1);
  
          const fechaMaxima = new Date(fechaDesde);
          fechaMaxima.setMonth(fechaDesde.getMonth() + 3);
  
          if (fechaHasta >= fechaMinima && fechaHasta <= fechaMaxima) {
            await api.post(
              '/api/objetivo_meta_usuario',
              {
                objetivoMeta: modalData.objetivoMeta._id,
                usuario: usuarioSeleccionado,
                fechaDesde: fechaDesde.toISOString(),
                fechaHasta: fechaHasta.toISOString(),
                valor: modalData.valor,
                creadoAdmin: false,
                cumplido: false,
              },
              {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'User-ID': usuario.id,
                },
              }
            );
          } else {
            throw new Error('Las metas deben tener un margen de fecha de 1 a 3 meses');
          }
        } else {
          await api.post(
            '/api/objetivo_meta_usuario',
            {
              objetivoMeta: modalData.objetivoMeta._id,
              usuario: usuarioSeleccionado,
              fechaDesde: fechaDesde.toISOString(),
              fechaHasta: fechaHasta.toISOString(),
              valor: modalData.valor,
              creadoAdmin: isAdmin,
              cumplido: false,
            },
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'User-ID': usuario.id,
              },
            }
          );
        }
      }
  
      // Reseteo de estado y llamadas a funciones
      setBotonCrear('');
      setBotonEditar('');
      fetchMetas(usuarioSeleccionado);
      setModalOpen(false);
  
    } catch (error) {
      let errors;
  
      if (error.response && error.response.data && error.response.data.error) {
        errors = error.response.data.error;
      } else {
        errors = [{ message: error.message }];
      }
      setAlertMessages(errors);
      setAlertOpen(true);
    }
  };

  const abrirModal = (editado = false, meta = null, esMeta) => {
    setIsEdit(editado);
    if (editado && meta) {
      const metaFormateada = {
        ...meta,
        fechaDesde: formatDate(meta.fechaDesde),
        fechaHasta: formatDate(meta.fechaHasta),
      };
      if(esMeta){
        setMetaSeleccionada(meta);
      }else{
        setObjetivoSeleccionado(meta);
      }
      setModalData(metaFormateada);
    } else {
      setModalData({
        objetivoMeta: '',
        valor: '',
        cumplido: false,
      });
    }
    setModalOpen(true);
  };

  const manejarCambioDeInput = (e) => {
    const { name, value, checked } = e.target;
  
    if (name === "meta") {
      setModalData((prevData) => ({
        ...prevData,
        objetivoMeta: { _id: value }, 
      }));
    } else if(name === "cumplido"){
      setModalData(prevState => ({
        ...prevState,
        [name]: checked,
      }));
    
    } else if (name === "fechaDesde" || name === "fechaHasta") {
     
      const formattedDate = formatDate3(value);
      setModalData((prevData) => ({
        ...prevData,
        [name]: formattedDate,
      }));
    }else {
      setModalData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleUsuarioChange = async (event) => {
    let usuarioId ="";
    if(event){
      usuarioId = event.target.value;
      setUsuarioSeleccionado(usuarioId);

    }else{
      usuarioId = usuarioSeleccionado;
    }
    if(usuarioId){
      fetchMetas(usuarioId);
    }
  }

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  }

  const formatDate2 = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
}

const formatDate3 = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${day}-${month}-${year}`; 
};

const eliminarRegistro = async (dato) => {
  try {
    const token = localStorage.getItem('token'); 
    const usuario = JSON.parse(localStorage.getItem('usuario')); 
    const headers = {
      'Authorization': `Bearer ${token}`,
      'User-ID': usuario.id,
    };

    await api.delete(`api/objetivo_meta_usuario/${dato._id}`, { headers });
    
    fetchMetas(usuarioSeleccionado);

  } catch (error) {
    console.error('Error al eliminar el registro:', error);
  }
}

const verMetasCumplidas = () => {
  setMetasCumplidas(!metasCumplidas);
};

const verObjetivosCumplidos = () => {
  setObjetivosCumplidos(!objetivosCumplidos);
};

const metasFiltradas = metas
  .filter((meta) => meta.creadoAdmin === false)
  .filter((meta) => metasCumplidas || !meta.cumplido);
  
const objetivosFiltrados = objetivos
  .filter((objetivo) => objetivo.creadoAdmin === true)
  .filter((objetivo) => objetivosCumplidos || !objetivo.cumplido);  

const cerrarAlerta = () => {
  setAlertOpen(false);
};
  
  return (
    <ThemeProvider theme={tema}>
      <Grid container  sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={12} component={Paper} elevation={6} square sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box sx={{flex: 1, display: 'flex', flexDirection: 'column', height: '100%', p: 2  }}>
            {isAdmin && (
              <FormControl fullWidth={false} margin="normal" sx={{ width: '300px' }}>
              <Select
                value={usuarioSeleccionado || ""} 
                onChange={handleUsuarioChange}
                onOpen={listarUsuarios}
                displayEmpty
                // sx={{ backgroundColor: 'white', color: 'black !important'}}
                sx={{ 
                  // backgroundColor: '#424242', 
                  backgroundColor: tema.palette.background.paper,
                  color: 'white',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: 'white !important',
                  },
                  '.MuiList-root': {
                    // backgroundColor: '#424242',
                    backgroundColor: tema.palette.background.paper,
                    color: 'white',
                  },
                }}
              >
                <MenuItem value="" disabled>Selecciona un usuario</MenuItem>
                {usuarios.map(usuario => (
                  <MenuItem key={usuario._id} value={usuario._id}>
                    {usuario.nombre}
                  </MenuItem>
                ))}
              </Select>
              </FormControl>
            )}

            {usuarioSeleccionado || !isAdmin ? (
              <>
                <Box sx={{ border: '1px solid lightgray', borderRadius: 2, mb: 5, mt:5 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography component="h1" variant="h5" sx={{ pl: 4, mt:1 }}>Objetivos</Typography>
                    {isAdmin && (
                      <Box>
                        <Button variant="contained" color="primary"onClick={() => {abrirModal(false); setBotonCrear('crearObjetivo');}} sx={{ ml: 4, mt:2 }}>Crear</Button>
                      </Box>
                    )}
                    <Button variant="contained"  onClick={verObjetivosCumplidos} sx={{ ml: 1, mt: 2, backgroundColor:"blue", '&:hover': {backgroundColor: "#151d4f", }}}>{objetivosCumplidos ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}</Button>
                  </Box>
                  <Divider sx={{ my: 2, borderColor: 'lightgray', opacity: 1 }}/>
                  {objetivosFiltrados.length === 0 ? (
                    <p>Aún no tienes objetivos asignados.</p>
                  ) : (
                    <Box sx={{ maxHeight: 'calc(100vh - 250px)', overflowY: 'auto' }}>
                      <TableContainer component={Paper} sx={{ width: '100%' }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Nombre</TableCell>
                              <TableCell>Fecha Ini</TableCell>
                              <TableCell>Fecha Fin</TableCell>
                              <TableCell>Valor</TableCell>
                              <TableCell>Completado</TableCell>
                              {isAdmin &&(
                                <TableCell>Acciones</TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {objetivosFiltrados.map((objetivo) => (
                              <TableRow
                                key={objetivo._id}
                              >
                                <TableCell>{objetivo.objetivoMeta.nombre}</TableCell>
                                <TableCell>{formatDate(objetivo.fechaDesde)}</TableCell>
                                <TableCell>{formatDate(objetivo.fechaHasta)}</TableCell>
                                <TableCell>{objetivo.valor}</TableCell>
                                <TableCell>
                                  <Checkbox checked={objetivo.cumplido} disabled 
                                    sx={{
                                      color: '#868686',
                                      '&.Mui-checked': {
                                        color: '#868686',
                                      },
                                      '& .MuiSvgIcon-root': {
                                        color: '#868686',
                                      },
                                    }}
                                  />
                                </TableCell>
                                {isAdmin && (
                                  <TableCell>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => {abrirModal(true, objetivo, false); setBotonEditar('editarObjetivo')}}
                                      sx={{
                                        '&:hover': { backgroundColor: '#636363' }
                                      }}
                                    >
                                      <i className="bi bi-pencil"></i>
                                    </Button>

                                    <Button
                                      variant="contained"
                                      color="error"
                                      onClick={() => eliminarRegistro(objetivo)}
                                      sx={{
                                        '&:hover': { backgroundColor: '#9e2828' }
                                      }}
                                    >
                                      <i className="bi bi-trash3"></i>
                                    </Button>
                                  </TableCell>
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                </Box>
                <Box sx={{ border: '1px solid lightgray', borderRadius: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography component="h1" variant="h5" sx={{ pl: 4, mt:1 }}>Metas</Typography>
                    <Button variant="contained" color="primary"onClick={() => {abrirModal(false); setBotonCrear('crearMeta')}} sx={{ ml: 4, mt:2 }} id="crearMeta">Crear</Button>
                    <Button variant="contained"  onClick={verMetasCumplidas} sx={{ ml: 1, mt: 2, backgroundColor:"blue", '&:hover': {backgroundColor: "#151d4f", }}}>{metasCumplidas ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}</Button>
                  </Box>
                  <Divider sx={{ my: 2, borderColor: 'lightgray', opacity: 1 }}/>
                  {metasFiltradas.length === 0 ? (
                    <p>Aún no tienes metas asignadas.</p>
                  ) : (
                    <Box sx={{ maxHeight: 'calc(100vh - 250px)', overflowY: 'auto' }}>
                      <TableContainer component={Paper} sx={{ width: '100%' }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Nombre</TableCell>
                              <TableCell>Fecha Ini</TableCell>
                              <TableCell>Fecha Fin</TableCell>
                              <TableCell>Valor</TableCell>
                              <TableCell>Completado</TableCell>
                              <TableCell>Acciones</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {metasFiltradas.map((meta) => (
                              <TableRow
                                key={meta._id}
                              >
                                <TableCell>{meta.objetivoMeta.nombre}</TableCell>
                                <TableCell>{formatDate(meta.fechaDesde)}</TableCell>
                                <TableCell>{formatDate(meta.fechaHasta)}</TableCell>
                                <TableCell>{meta.valor}</TableCell>
                                <TableCell>
                                  <Checkbox checked={meta.cumplido} disabled 
                                    sx={{
                                      color: '#868686',
                                      '&.Mui-checked': {
                                        color: '#868686',
                                      },
                                      '& .MuiSvgIcon-root': {
                                        color: '#868686',
                                      },
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Button 
                                    variant="contained" 
                                    color="secondary" 
                                    onClick={() => {abrirModal(true, meta, true); setBotonEditar('editarMeta')}} 
                                    sx={{'&:hover': {backgroundColor: '#636363'}}}
                                  >
                                    <i className="bi bi-pencil"></i>
                                  </Button>
                                        
                                  <Button 
                                    variant="contained" 
                                    color="error" 
                                    onClick={() => eliminarRegistro(meta)} 
                                    sx={{'&:hover': {backgroundColor: '#9e2828'}}}
                                  >
                                    <i className="bi bi-trash3"></i>
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <p>Seleccione un usuario para ver sus metas.</p>
            )}
          </Box>
        </Grid>
      </Grid>
      <Modal open={modalOpen} onClose={() => {setModalOpen(false); setMetaSeleccionada(null); setObjetivoSeleccionado(null); setBotonCrear(''); setBotonEditar('');}}>
        <Box sx={{ ...modalStyle, width: '80%', maxWidth: '1000px', maxHeight: '80vh', overflowY: 'auto' }}>
          {/* if(botonCrear ) */}
          <Typography variant="h6" component="h2" sx={{ mb: 2, backgroundColor: 'transparent', textAlign: 'center' }}>{botonCrear === 'crearMeta' && !isEdit ? 'Crear Meta' : botonEditar === 'editarMeta' && isEdit ? 'Editar Meta' : botonCrear === 'crearObjetivo' && !isEdit ? 'Crear Objetivo' : 'Editar Objetivo'}</Typography>
            <InputLabel>{botonCrear === 'crearMeta' || botonEditar === 'editarMeta'? 'Seleccionar Meta' : 'Seleccionar Objetivo'}</InputLabel>
            <Select
              required
              id="meta"
              name="meta"
              autoComplete="meta"
              value={modalData.objetivoMeta? modalData.objetivoMeta._id : ''}
              onChange={manejarCambioDeInput}
              disabled={isEdit}
              sx={{
                backgroundColor: tema.palette.background.paper,
                color: 'white',
                '& .MuiSelect-select': {
                  backgroundColor: tema.palette.background.paper,
                  color: 'white',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
                '& .MuiInputLabel-root': {
                  color: 'white !important',
                },
                '& .MuiMenuItem-root': {
                  backgroundColor: tema.palette.background.paper,
                  color: 'white',
                  '&:hover': {
                    backgroundColor: tema.palette.background.paper,
                  },
                },
                '& .MuiSelect-icon': {
                  color: 'white',
                },
                width: '65%',
              }}
            >
              <MenuItem value="" disabled>Seleccione una opción</MenuItem>
              {availableMetas.map((meta) => (
                <MenuItem key={meta._id} value={meta._id}>{meta.nombre}</MenuItem>
              ))}
              
            </Select>
            <InputLabel>Fecha Desde</InputLabel>
            <TextField
              type="date"
              margin="normal"
              required
              id="fechaDesde"
              name="fechaDesde"
              autoComplete="fechaDesde"
              value={modalData.fechaDesde ? formatDate2(modalData.fechaDesde) : undefined}
              onChange={manejarCambioDeInput}
              sx= {{
                '& input': {
                  color: 'white',
                },
                '& ::-webkit-calendar-picker-indicator': {
                  filter: 'invert(1) !important',
                },
                borderRadius: '2px',
                borderWidth: '1px',
                borderColor: '#e91e63',
                border: '1px solid',
                backgroundColor: tema.palette.background.paper,
                width: '65%',
              }}
            />
            <InputLabel>Fecha Hasta</InputLabel>
            <TextField
              type="date"
              margin="normal"
              required
              id="fechaHasta"
              name="fechaHasta"
              autoComplete="fechaHasta"
              value={modalData.fechaHasta ? formatDate2(modalData.fechaHasta) : undefined}
              onChange={manejarCambioDeInput}
              sx= {{
                '& input': {
                  color: 'white',
                },
                '& ::-webkit-calendar-picker-indicator': {
                  filter: 'invert(1) !important',
                },
                borderRadius: '2px',
                borderWidth: '1px',
                borderColor: '#e91e63',
                border: '1px solid',
                backgroundColor: tema.palette.background.paper,
                width: '65%',
              }}
            />
            <InputLabel>Valor</InputLabel>
            <TextField
              type="number"
              margin="normal"
              required
              id="valor"
              name="valor"
              autoComplete="valor"
              value={modalData.valor}
              onChange={manejarCambioDeInput}
              sx= {{
                '& input': {
                  color: 'white',
                },
                borderRadius: '2px',
                borderWidth: '1px',
                borderColor: '#e91e63',
                border: '1px solid',
                backgroundColor: tema.palette.background.paper,
                width: '65%',
              }}
            />

            {isEdit && (
              <Box sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      margin="normal"
                      id="cumplido"
                      name="cumplido"
                      autoComplete="cumplido"
                      checked={modalData.cumplido}
                      onChange={manejarCambioDeInput}
                    />
                  }
                  label={modalData.creadoAdmin? "Cumplido" : "Cumplida"}
                />
              </Box>
            )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button onClick={() => {setModalOpen(false); setMetaSeleccionada(null); setObjetivoSeleccionado(null); setBotonCrear(''); setBotonEditar('');}} color="error" variant="outlined">Cancelar</Button>
            <Button onClick={handleCreateUserMeta} variant="contained" color="primary">{isEdit ? 'Editar' : 'Guardar'}</Button>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={alerta}
        autoHideDuration={6000}
        onClose={cerrarAlerta}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={cerrarAlerta} severity="error" sx={{ width: '100%' }}>
          <AlertTitle>Error</AlertTitle>
          {mensajeAlerta.map((error, index) => (
            <div key={index}>
              {error.path && `${error.path.join('.')}: `}{error.message}
            </div>
          ))}
        </Alert>
      </Snackbar>
    </ThemeProvider>  
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default ObjetivosMetas;